import { useEffect } from "react"
import { Route, BrowserRouter as Router, Routes, useRoutes } from "react-router-dom"
import Home from "./pages"
import History from "./pages/history"
import Orders from "./pages/orders"
import Room from "./pages/room/[id]"
import Products from "./pages/products/[id]/[id]"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import "./utils/i18n"
import WithAuth from "./hoc/withAuth"
import RequireAuth from "./hoc/RequireAuth"
import { setDefaultLocale } from "react-datepicker"
import moment from "moment"
import "moment/locale/ru"
// import "moment/locale/fr"
// import "moment/locale/zh-cn"
import "moment/locale/th"
import { langWithCurrentMomentCode } from "./utils/helpers"
import { registerSW } from "./utils/serviceWorkerRegistration"
import RoomCategoryServices from "./components/RoomLayout/RoomCategoryServices"
import RoomServicesList from "./components/RoomServicesList/RoomServicesList"
import Page404 from "./pages/404"
import ReviewsPage from "./pages/reviews"
import EmployeesPage from "./pages/employees"
import ArticlesPage from "./pages/articles/[...id]"
import OrderServicePage from "./pages/products/[id]/[productId]"
import OrderInfoPage from "./pages/order/[id]"
import { Tooltip } from "react-tooltip"

const RoutesArr = () =>
  useRoutes(
    [
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: ":locale/",
        element: <Home />,
      },
      {
        path: "/history",
        element: (
          <RequireAuth>
            <History />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/history",
        element: (
          <RequireAuth>
            <History />
          </RequireAuth>
        ),
      },
      {
        path: "/orders",
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: "/reviews",
        element: (
          <RequireAuth>
            <ReviewsPage />
          </RequireAuth>
        ),
      },
      {
        path: "/employees",
        element: (
          <RequireAuth>
            <EmployeesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/articles",
        element: (
          <RequireAuth>
            <ArticlesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/articles/:id",
        element: (
          <RequireAuth>
            <ArticlesPage />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/orders",
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: "/products/:id",
        element: <Products />,
      },
      {
        path: "/products/:id/:productId",
        element: (
          <RequireAuth>
            <OrderServicePage />
          </RequireAuth>
        ),
      },
      {
        path: "/order/:id",
        element: (
          <RequireAuth>
            <OrderInfoPage />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/products/:id",
        element: <Products />,
      },
      {
        path: "/object/:id/",
        element: <Room />,
        children: [
          {
            // path: "",
            index: true,
            element: <RoomServicesList />,
          },
          {
            path: "category/:category_id",
            element: <RoomCategoryServices />,
          },
        ],
      },
      {
        path: ":locale/object/:id",
        element: <Room />,
      },
    ].map((el) => ({ path: el.path, element: el.element, children: el.children })),
  )

function App() {
  const currentLang = localStorage.getItem("i18nextLng")

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
    setDefaultLocale(String(currentLang) || "en")
  }, [currentLang])

  return (
    <Provider store={store}>
      <WithAuth />
      <Router>
        <RoutesArr />
      </Router>
      <Tooltip id="globalTooltip" className={"tooltip-base"} place="top" />
    </Provider>
  )
}

export default App
