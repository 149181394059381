import { useEffect, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import Search from "../Search/Search"
import OrdersLine from "../OrdersLine/OrdersLine"
import ServicesList from "../ServicesList/ServicesList"
import SearchList from "../SearchList/SearchList"
import { useTranslation } from "react-i18next"
import { useGetProductsListMutation, useLazyGetServiceListQuery } from "../../redux/api/main"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { updateServiceList } from "../../redux/slice/main"
import { selectAuth, selectIsAllowProposeNewServiceMain } from "../../redux/slice/auth"
import { setIsSearchVal } from "../../redux/slice/params"
import useWindowSize from "../../hooks/useWindowSize"
import ImportantEvents from "../ImportantEvents/ImportantEvents"
import MainLayoutLoader from "../LayoutLoaders/MainLayoutLoader"
import IconBtn from "../Assets/IconBtn/IconBtn"
import { QRMobIcon } from "../../icons/QRMobIcon"
import clsx from "clsx"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import styles from "./MainLayout.module.scss"

const MainLayout = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [getProductsList] = useGetProductsListMutation()
  const [getServiceList] = useLazyGetServiceListQuery()

  const { serviceListLoading } = useAppSelector((state) => state.main)
  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const user = useAppSelector(selectAuth)

  const { isDesktop } = useWindowSize()

  const [searchVal, setSearchVal] = useState<string | number>("")
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const currentLang = localStorage.getItem("i18nextLng") || "en"
  const [serviceList, setServiceList] = useState<any[]>([])

  useEffect(() => {
    getServiceList()
      .then((res) => {
        dispatch(updateServiceList(res?.data ?? []))
      })
      .catch((err: any) => console.error(err))
  }, [currentLang])

  useEffect(() => {
    setIsSearchLoading(true)
    if (searchVal === "") setIsSearchLoading(false)

    dispatch(setIsSearchVal(Boolean(searchVal && searchVal !== "")))

    if (typeof searchVal === "string" && searchVal.trim().length > 0) {
      const timeoutId = setTimeout(() => {
        getProductsList({ keywords: searchVal })
          .unwrap()
          .then((res) => {
            setIsSearchLoading(false)
            setServiceList(res.data.aItems)
          })
          .catch((err) => console.warn(err))
      }, 500)

      return () => clearTimeout(timeoutId)
    }
  }, [searchVal])

  return serviceListLoading ? (
    isDesktop ? (
      <MainLayoutLoader />
    ) : (
      <>
        <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={serviceListLoading} />
        <OrdersLine isLoading={serviceListLoading} />
        <ServicesList />
      </>
    )
  ) : (
    <>
      {searchVal && <TitleBack title={t("search")} setSearchVal={setSearchVal} searchVal={searchVal} />}
      <div
        className={clsx(
          styles["main-layout-search-block"],
          !isDesktop && styles["main-layout-search-block__added-padding"],
        )}
      >
        <Search searchVal={searchVal} setSearchVal={setSearchVal} isLoading={serviceListLoading} />
        {!isDesktop && (
          <IconBtn
            className={styles["main-layout-search-block__QR-btn"]}
            icon={<QRMobIcon />}
            onClick={() => {
              console.log("open QR func")
            }}
          />
        )}
      </div>
      {!searchVal && <ImportantEvents />}

      {searchVal ? (
        <SearchList list={serviceList} isSearchLoading={isSearchLoading} searchVal={searchVal} />
      ) : (
        <>
          {user.token && !isDesktop && <OrdersLine />}
          <ServicesList />
        </>
      )}

      {isAllowProposeNewServiceMain && <NoFindBtn isLoading={serviceListLoading} />}
    </>
  )
}

export default MainLayout
